import React from "react"

import { withDetailsForm } from "./withDetailsForm"
import {
  Form,
  Content,
  Title,
  Description,
  SpecialMessage,
} from "./DetailsFormStyles"
import { TextField } from "../../../TextField/TextField"
import { Errors, Error, Submit } from "../../Layout/LayoutStyles"
import { Popup } from "../../../Popup/Popup"
import { StyledButton } from "../../../Styled/Button"
// import { PhoneInput } from "../../../PhoneInput/PhoneInput"

export const DetailsForm = withDetailsForm(
  ({
    customer,
    handleChange,
    handlePhoneChange,
    handleSubmit,
    password,
    confirmPassword,
    handlePasswordChange,
    handleConfirmedPasswordChange,
    isPasswordValid,
    showPasswordNotMatchMessage,
    saving,
    errors,
    showPasswordChangedPopup,
    handlePasswordChangedPopup,
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalEmailLabel,
    additionalPhoneLabel,
    additionalPhonePlaceholder,
    additionalConfirmPasswordLabel,
    additionalPasswordLabel,
    additionalSubmitButton,
    additionalSubmittingButton,
    additionalPasswordChangedTitle,
    additionalPasswordChangedDescription,
    additionalPasswordChangedButtonText,
    additionalPasswordNotMatchMessage,
  }): JSX.Element => (
    <>
      <Form onSubmit={handleSubmit}>
        <TextField
          name="firstName"
          type="text"
          value={customer?.firstName}
          onChange={handleChange}
          label={additionalFirstNameLabel}
          spacing
        />
        <TextField
          name="lastName"
          type="text"
          value={customer?.lastName}
          onChange={handleChange}
          label={additionalLastNameLabel}
          spacing
        />
        <TextField
          name="email"
          type="email"
          value={customer?.email}
          onChange={handleChange}
          label={additionalEmailLabel}
          spacing
          readOnly
        />
        <TextField
          name="phone"
          type="text"
          value={customer?.phone}
          onChange={handlePhoneChange}
          label={additionalPhoneLabel}
          placeholder={additionalPhonePlaceholder}
          spacing
        />
        <SpecialMessage>
          Please input your <b>mobile number</b> above. Example: +61 4XX XXX XXX
        </SpecialMessage>
        <TextField
          name="password"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          label={additionalPasswordLabel}
          spacing
        />
        <TextField
          name="confirmPassword"
          type="password"
          value={confirmPassword}
          onChange={handleConfirmedPasswordChange}
          label={additionalConfirmPasswordLabel}
          spacing
        />
        {showPasswordNotMatchMessage && (
          <Error>{additionalPasswordNotMatchMessage}</Error>
        )}
        {errors?.length > 0 && (
          <Errors>
            {errors?.map((error, index) => (
              <Error key={index}>{error?.message}</Error>
            ))}
          </Errors>
        )}
        <Submit
          type={`submit`}
          size={"primary"}
          colour={"black"}
          disabled={saving || !isPasswordValid}
        >
          {!saving ? additionalSubmitButton : additionalSubmittingButton}
        </Submit>
      </Form>
      <Popup
        active={showPasswordChangedPopup}
        setActive={handlePasswordChangedPopup}
        width={"md"}
      >
        <Content>
          <Title>{additionalPasswordChangedTitle}</Title>
          <Description>{additionalPasswordChangedDescription}</Description>
          <StyledButton onClick={() => handlePasswordChangedPopup(false)}>
            {additionalPasswordChangedButtonText}
          </StyledButton>
        </Content>
      </Popup>
    </>
  ),
)
