import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import {
  useCustomerDetails,
  useCustomerLogout,
} from "../../../../hooks/useCustomer"

export const withDetailsForm = Component => ({ name = "DetailsForm" }) => {
  const [password, setPassword] = useState("")
  const [confirmedPassword, setConfirmedPassword] = useState("")
  const [passwordChanged, setPasswordChanged] = useState(false)
  const [showPasswordChangedPopup, setShowPasswordChangedPopup] = useState(
    false,
  )

  const {
    customer,
    setCustomer,
    updateCustomer,
    saving,
    errors,
  } = useCustomerDetails()

  const { logoutCustomer } = useCustomerLogout()

  const { dashboard } = useStaticQuery(graphql`
    query SANITY_PAGE_ACCOUNT_DASHBOARD_FORM {
      dashboard: sanityPageAccountDashboard {
        additionalFirstNameLabel
        additionalLastNameLabel
        additionalEmailLabel
        additionalPhoneLabel
        additionalPhonePlaceholder
        additionalPasswordLabel
        additionalConfirmPasswordLabel
        additionalSubmitButton
        additionalSubmittingButton
        additionalPasswordChangedTitle
        additionalPasswordChangedDescription
        additionalPasswordChangedButtonText
        additionalPasswordNotMatchMessage
      }
    }
  `)

  const {
    additionalFirstNameLabel,
    additionalLastNameLabel,
    additionalEmailLabel,
    additionalPhoneLabel,
    additionalPhonePlaceholder,
    additionalSubmitButton,
    additionalSubmittingButton,
    additionalPasswordChangedTitle,
    additionalPasswordChangedDescription,
    additionalPasswordChangedButtonText,
    additionalPasswordLabel,
    additionalConfirmPasswordLabel,
    additionalPasswordNotMatchMessage,
  } = dashboard || {}

  const handleSubmit = async event => {
    event.preventDefault()
    setPasswordChanged(false)
    const data =
      password && password === confirmedPassword
        ? { ...customer, password }
        : { ...customer }
    await updateCustomer(data)
    if (password && password === confirmedPassword) {
      setPasswordChanged(true)
    }
  }

  const handleChange = ({ target: { name, value } }) =>
    setCustomer(prevState => ({
      ...prevState,
      [name]: value,
    }))

  const handlePhoneChange = ({ target: { value } }) => {
    setCustomer(prevState => ({
      ...prevState,
      phone: `${value.replace(" ", "")}`,
    }))
  }

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value)
  }

  const handleConfirmedPasswordChange = ({ target: { value } }) => {
    setConfirmedPassword(value)
  }

  const handlePasswordChangedPopup = value => {
    setShowPasswordChangedPopup(value)
    logoutCustomer()
  }

  useEffect(() => {
    if (errors?.length === 0 && passwordChanged) {
      setShowPasswordChangedPopup(true)
    }
  }, [passwordChanged, errors])

  const isPasswordValid = password === confirmedPassword

  const [
    showPasswordNotMatchMessage,
    setShowPasswordNotMatchMessage,
  ] = useState(false)

  useEffect(() => {
    if (
      password?.length > 0 &&
      confirmedPassword?.length > 0 &&
      password !== confirmedPassword
    ) {
      setShowPasswordNotMatchMessage(true)
    } else if (
      password?.length > 0 &&
      confirmedPassword?.length > 0 &&
      password === confirmedPassword
    ) {
      setShowPasswordNotMatchMessage(false)
    }
  }, [password, confirmedPassword])

  Component.displayName = name
  return (
    <Component
      customer={customer}
      handleChange={handleChange}
      handlePhoneChange={handlePhoneChange}
      handleSubmit={handleSubmit}
      password={password}
      confirmedPassword={confirmedPassword}
      isPasswordValid={isPasswordValid}
      showPasswordNotMatchMessage={showPasswordNotMatchMessage}
      handlePasswordChange={handlePasswordChange}
      handleConfirmedPasswordChange={handleConfirmedPasswordChange}
      saving={saving}
      errors={errors}
      showPasswordChangedPopup={showPasswordChangedPopup}
      handlePasswordChangedPopup={handlePasswordChangedPopup}
      additionalFirstNameLabel={additionalFirstNameLabel}
      additionalLastNameLabel={additionalLastNameLabel}
      additionalEmailLabel={additionalEmailLabel}
      additionalPhoneLabel={additionalPhoneLabel}
      additionalPhonePlaceholder={additionalPhonePlaceholder}
      additionalConfirmPasswordLabel={additionalConfirmPasswordLabel}
      additionalSubmitButton={additionalSubmitButton}
      additionalSubmittingButton={additionalSubmittingButton}
      additionalPasswordChangedTitle={additionalPasswordChangedTitle}
      additionalPasswordChangedDescription={
        additionalPasswordChangedDescription
      }
      additionalPasswordChangedButtonText={additionalPasswordChangedButtonText}
      additionalPasswordLabel={additionalPasswordLabel}
      additionalPasswordNotMatchMessage={additionalPasswordNotMatchMessage}
    />
  )
}
