import tw, { styled } from "twin.macro"
import { TinyBody } from "../../../Styled/Text"

export const Form = tw.form`
  mt-3 md:mt-0
`

export const Content = tw.div`
  pt-4 pb-6-4 flex flex-col items-center justify-center w-full
`

export const Title = tw.h2`
  mb-3-2 md:mb-4-8 text-18 md:text-20 leading-1.56 font-medium px-1-6 text-center
`

export const Description = tw.p`
  text-16 mb-2-4
`

export const SpecialMessage = styled(TinyBody)`
  ${tw`text-grey -mt-0-8 mb-0-8`}
`
