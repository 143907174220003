import React, { useEffect } from "react"

import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useCustomerContext } from "../../../hooks/useCustomer"
import { useFunctions } from "../../../hooks/useFunctions"

export const withDetails = Component => ({
  name = "Details",
  page,
  uri,
  location,
}) => {
  const { customer } = useCustomerContext()

  const { checkout } = useCheckoutContext()

  const { checkoutUrl } = useCheckout()

  const { checkoutMultipass } = useFunctions()

  const { title } = page || {}

  const paths = [
    {
      title,
      url: uri,
    },
  ]

  useEffect(() => {
    if (location?.state?.checkout && customer?.id) {
      checkoutMultipass(customer.email, checkout.id, checkoutUrl)
    }
  }, [location, customer, checkout.id, checkoutMultipass, checkoutUrl])

  Component.displayName = name
  return <Component uri={uri} paths={paths} />
}
