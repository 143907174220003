import React from "react"
import { graphql } from "gatsby"

import { withAuthentication } from "../hoc/withAuthentication"
import { Details as Page } from "../components/Customer/Details/Details"

import { useHotjar } from '../hooks/useHotjar'

export const query = graphql`
  query {
    page: sanityPageAccountDashboard {
      title
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component = withAuthentication(({ data, ...props }) => {
  const { trackHotjarEvent } = useHotjar()

  trackHotjarEvent("account_page")

  return <Page {...props} {...data} /> 
})
  
export default Component
