import React from "react"

import { withDetails } from "./withDetails"
import { DetailsForm } from "./Form/DetailsForm"
import { Layout } from "../Layout/Layout"

export const Details = withDetails(
  ({ uri, paths }): JSX.Element => (
    <Layout uri={uri} paths={paths}>
      <DetailsForm />
    </Layout>
  )
)
